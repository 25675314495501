 <template>
  <v-container fluid>
    <TableViewComponent
      title="Table établissements.JURIS"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un établissement.JURIS"
      itemLabel="établissement.juris"
      itemsLabel="établissements.juris"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cet etablissement.juris ?"
      :loading="loading"
      :vcols="{ xl: '8', lg: '9', md: '10', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete" 
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { EstablishmentJurisService } from "@/service/etablishment/establishment_juris_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableEstablishmentJuris",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le classe d'accès au service */
      serviceJuris: null,
      serviceEstablishment: null,
      serviceLegalStructure:null,

    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.establishmentsJuris.add.path);
    },
    async load() {
      try {
        this.loading = true;

        // Récupération des établisssement.juris, des établissements et les structures juridiques
        let juris = await this.serviceJuris.getAll();
        let establishments = await this.serviceEstablishment.getEstablishments();
        let legalStructures = await this.serviceLegalStructure.getAll();

        this.entities = [];

        // Boucle de traitement pour l'affichage des données de l'établissement et de Juris
        establishments.forEach((establishment) => {
          let entity = {};
          // Tentative de trouver le Juris correspondant à l'établissement en cours
          let found = juris.find((j) => j.establishment == establishment.id);
          // Récupération de la structure juridique
          let legalStructure = legalStructures.find((l) => l.id == establishment.legalStructure);

          entity.idEstablishment = establishment.id;
          entity.digitalName = establishment.digitalName;

          entity.legalStructureName = '-';
          if(legalStructure) {
            entity.legalStructureName = legalStructure.digitalName;
          }

          // Si le pendant Juris a été trouvé
          if (found) {
            entity.finessName = found.finessName || "-";
            entity.finessNumber = found.finessNumber || "-";
            entity.siret = found.siret || "-";
            entity.ape = found.ape || "-";
            entity.establishmentMainManager = found.establishmentMainManager || "-";

            // action pour voir le détail
            entity.view = () => {
            this.$router.push(routes.establishmentsJuris.view.root + "/" + entity.idEstablishment);
            };

            //action de modification
            entity.edit = () => {
              this.$router.push(routes.establishmentsJuris.edit.root + "/" + entity.idEstablishment);
            };
            
            // action pour la suppression 
            entity.delete = async () => {
              try {
                await this.serviceJuris.delete(entity.idEstablishment);
                this.load();
              } catch (error) {
                console.error(error);
                this.addErrorToSnackbar(
                  "suppression de la partie juris d'un établissement : " +
                    (exceptions.toMessage(error) || "problème technique")
                );
              }
            };

            this.entities.push(entity);
          }
          // Traitement pour l'affichage des établissements qui n'ont pas de JURIS
          // } else { 
          //   entity.finessName = "x";
          //   entity.finessNumber = "x";
          //   entity.siret = "x";
          //   entity.ape = "x";
          //   entity.establishmentMainManager = "x";
          //   entity.disableActions = true;
          // } 
          // this.entities.push(entity);         
        });// END FOREACH

        // Tri du tableau par ordre alphabétique (au cas où il se serait perdu dans le foreach)
        this.entities.sort(function(a, b) {
          return a.digitalName.localeCompare(b.digitalName);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
        },
        {
          text: "Structure juridique",
          align: "start",
          sortable: true,
          value: "legalStructureName",
        },
        {
          text: "Siret",
          align: "start",
          width: "180px",
          sortable: true,
          value: "siret",
        },
        {
          text: "Code APE (établissement secondaire)",
          align: "start",
          sortable: false,
          width: "80px",
          value: "ape",
        },
        // {
        //   text: "Administrateur principal d'établissement",
        //   align: "start",
        //   sortable: true,
        //   value: "establishmentMainManager",
        // },
        {
          text: "Nom finess",
          align: "start",
          sortable: true,
          value: "finessName",
        },
        {
          text: "Numéro Finess",
          align: "start",
          sortable: true,
          value: "finessNumber",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
    computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditEstablishmentJuris];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditEstablishmentJuris, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadEstablishmentJuris];
    },
  },
  mounted() {
    //on instancie les services
    this.serviceJuris = new EstablishmentJurisService(this.$api.getEstablishmentJurisApi());
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.serviceLegalStructure = new LegalStructureService(this.$api.getLegalStructuresApi());

    this.load();
  },

};
</script>

<style>
</style>